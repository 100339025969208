<template>
  <div class="tap6">
    <div class="tw-text-xl tw-w-1/4 tw-font-bold tw-mb-6">Additional Data</div>
    <div class="sec1">
      <b-row>
        <b-col cols="1">
          <h4 class="mb-2">Id</h4>
        </b-col>
        <b-col cols="5">
          <h4 class="mb-2">Key</h4>
        </b-col>
        <b-col cols="6">
          <div class="render-language">
            <h4 class="mb-2">Value</h4>
            <span v-if="selectLang?.name" class="m-0 text-right">
              ({{ selectLang.name }})
            </span>
          </div>
        </b-col>
      </b-row>

      <div v-if="newData.length > 0">
        <b-row v-for="(item, index) in newData" :key="index">
          <b-col cols="1">
            <p class="m-0">
              {{ handleId(item.eventInfoId) }}
            </p>
          </b-col>
          <b-col cols="5">
            <InputText
              v-model="filterLang(item.additionalLanguageData).topic"
              placeholder="Value"
              textFloat=""
              type="text"
              name="text"
              :isRequired="selectLang.indexLang == 0 && true"
              :isValidate="
                v.form.additionalData.$each[index].additionalLanguageData
                  .$each[0].topic.$error
              "
              :v="
                v.form.additionalData.$each[index].additionalLanguageData
                  .$each[0].topic
              "
            />
          </b-col>
          <b-col cols="6">
            <div class="input-value">
              <InputText
                v-model="filterLang(item.additionalLanguageData).detail"
                class="input"
                placeholder="Value"
                textFloat=""
                type="text"
                name="text"
                :isRequired="selectLang.indexLang == 0 && true"
                :isValidate="
                  v.form.additionalData.$each[index].additionalLanguageData
                    .$each[0].detail.$error
                "
                :v="
                  v.form.additionalData.$each[index].additionalLanguageData
                    .$each[0].detail
                "
              />
              <font-awesome-icon
                icon="trash-alt"
                class="ml-2 pointer icon-delete"
                @click="removeItem(item)"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="no-data" v-else>
        <h3>No additional data</h3>
      </div>

      <div class="add-data">
        <b-icon @click="addData" icon="plus-square-fill"> </b-icon>
        <p @click="addData" class="m-0 ml-2 underline">Add Data</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdditionalDataTab",
  props: {
    data: {
      type: Array,
      default: null,
    },
    selectLang: {
      type: Object,
      default: null,
    },
    v: {
      type: Object,
      require: false,
    },
  },
  watch: {},
  computed: {
    newData() {
      let arr = this.data.filter((x) => x.isDisplay != 0);
      return arr;
    },
  },
  methods: {
    removeItem(obj) {
      const index = this.data
        .map((e) => e.eventInfoId)
        .indexOf(obj.eventInfoId);
      this.data[index].isDisplay = 0;
      // this.data.splice(index, 1);
    },

    addData() {
      const index = this.data.length + 1;

      this.data.push({
        eventInfoId: `new${index}`,
        sortOrder: index,
        isDisplay: 1,
        additionalLanguageData: [
          {
            id: 1,
            languageId: 2,
            topic: "",
            detail: "",
          },
          {
            id: 2,
            languageId: 1,
            topic: "",
            detail: "",
          },
        ],
      });
    },

    handleId(id) {
      let newId = "";

      const typeId = typeof id;

      if (typeId == "string") {
        newId = "N";
      } else {
        newId = id;
      }

      return newId;
    },

    filterLang(arr) {
      const final = this.$services.utils.fillterLang(
        arr,
        this.selectLang.languageId
      );

      return final;
    },
  },
};
</script>

<style lang="scss" scoped>
.tap6 {
  background: white;
  padding: 15px;
  min-height: 60vh;

  .title {
    font-size: 18px;
  }

  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .input-value {
    display: flex;
    align-items: center;

    .input {
      width: 100%;
    }
  }

  .add-data {
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    color: var(--secondary-color);

    .underline {
      text-decoration: underline;
    }
  }

  .icon-delete {
    margin-bottom: 15px;
    color: var(--secondary-color);
  }

  .render-language {
    display: flex;
    justify-content: space-between;

    .text-right {
      float: right;
      font-weight: 100;
      font-size: 14px;
    }
  }

  // nodata
  .no-data {
    text-align: center;
    padding: 15px 0px;
    opacity: 0.4;
  }
}

// ! Override
.btn-filter {
  width: 100%;
  max-width: 120px;
}
</style>
