<template>
  <div class="text-center text-black my-2" v-if="loading">
    <b-spinner class="align-middle"></b-spinner>
    <strong class="ml-2">Loading...</strong>
  </div>
  <div v-else>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row>
        <b-col>
          <div class="my-3">
            <InputText
              v-model="form.translationList[indexLanguage].name"
              class="input"
              :textFloat="`ServiceName`"
              placeholder="ServiceName"
              type="text"
              name="name"
              isRequired
              :selectLang="language"
              :v="v.form.translationList.$each[indexLanguage].name"
              :isValidate="
                v.form.translationList.$each[indexLanguage].name.$error
              "
            />
          </div>
        </b-col>
        <b-col>
          <div class="my-3">
            <InputText
              v-model="form.urlKey"
              class="input"
              :textFloat="`urlKey`"
              placeholder="urlKey"
              type="text"
              name="name"
              isRequired
              :v="v.form.urlKey"
              :isValidate="v.form.urlKey.$error"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="my-3">
            <UploadFile
              textFloat="Images"
              placeholder="Please Choose File"
              format="image"
              name="file"
              text="* ขนาดรูปภาพที่แนะนำ 800 * 450"
              accept="image/*"
              id="uploadfile"
              required="Please select image."
              isRequired
              :fileName="form.imgUrl"
              v-model="form.base64"
              @onFileChange="onFileChange($event)"
              :v="v.form.imgUrl"
              :isValidate="v.form.imgUrl.$error"
            />
          </div>
          <div
            class="render-image"
            :style="`background-image:url(${this.form.imgShowUrl})`"
          ></div>
        </b-col>
      </b-row>
      <b-row
        ><b-col cols="12">
          <div class="my-3">
            <InputTextArea
              v-model="form.translationList[indexLanguage].caption"
              class="input"
              :textFloat="`Caption`"
              placeholder="Caption"
              type="text"
              rows="4"
              name="name"
              :selectLang="language"
            /></div></b-col
      ></b-row>
      <!-- <b-row>
        <b-col cols="12">
          <div class="my-3">
            <InputTextArea
              v-model="form.translationList[indexLanguage].shortDescription"
              class="input"
              :textFloat="`ShortDescription`"
              placeholder="ShortDescription"
              type="text"
              rows="4"
              name="name"
              isRequired
              :selectLang="language"
              :v="v.form.translationList.$each[indexLanguage].shortDescription"
              :isValidate="
                v.form.translationList.$each[indexLanguage].shortDescription
                  .$error
              "
            /></div
        ></b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <InputSelect
            v-model="form.brandId"
            :options="optionBrand"
            title="Brand"
            class="mt-2"
            valueField="id"
            textField="name"
            isRequired
            :v="v.form.brandId"
            :isValidate="v.form.brandId.$error"
          />
        </b-col>
        <b-col>
          <InputSelect
            v-model="form.categoryId"
            :options="optionCategory"
            title="Category"
            class="mt-2"
            valueField="id"
            textField="name"
            isRequired
            :v="v.form.categoryId"
            :isValidate="v.form.categoryId.$error"
        /></b-col>
      </b-row>
      <b-row
        ><b-col cols="6">
          <div class="my-3">
            <InputText
              v-model="form.eventGroupPrice"
              class="input"
              :textFloat="`Price`"
              placeholder="Price"
              type="number"
              name="price"
              isRequired
              :v="v.form.eventGroupPrice"
              :isValidate="v.form.eventGroupPrice.$error"
            />
          </div> </b-col
      ></b-row>
      <b-row v-show="visibleRecommend"
        ><b-col cols="12">
          <div class="my-3">
            <b-form-checkbox
              v-model="form.isRecommend"
              id="isRecommend"
              name="isRecommend"
              :value="1"
              :unchecked-value="0"
            >
              Recommend
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-show="visibleRecommend">
          <div class="my-3">
            <InputText
              v-model="form.sortOrder"
              class="input"
              :textFloat="`SortOrder`"
              placeholder="SortOrder"
              type="text"
              name="name"
              :disabled="form.isRecommend <= 0"
            />
          </div>
        </b-col>
        <b-col>
          <div class="my-3 tw-flex tw-items-center tw-h-full">
            <label
              class="tw-text-base tw-font-bold tw-mt-2"
              :class="[
                `${
                  form.previewOrPublish === true
                    ? 'tw-text-green-500'
                    : 'tw-text-red-500'
                }`,
              ]"
              >{{
                form.previewOrPublish === true ? "Active" : "Inactive"
              }}</label
            >

            <b-form-checkbox
              @change="onChangeStatus(form)"
              v-model="form.previewOrPublish"
              class="pointer tw-ml-4"
              switch
              :value="true"
              :unchecked-value="false"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
export default {
  name: "EventGroupDetailTab",

  components: { UploadFile, AutocompleteCustom },
  props: {
    form: {
      type: Object,
      required: true,
    },
    indexLanguage: {
      type: Number,
      required: true,
    },
    language: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    visibleRecommend: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      imageDisplay: "",
      optionBrand: [],
      optionCategory: [],
    };
  },
  watch: {
    loading(val) {
      if (val === false) {
        this.getOptionBrand();
        this.getOptionCategory();
        this.imageDisplay = this.form.imgShowUrl;
      }
    },
    "form.isRecommend"(val) {
      if (val === 0) {
        this.form.sortOrder = null;
      }
    },
  },
  methods: {
    async getOptionBrand(keyword) {
      const brandData = await this.$services.master.getOptionBrand(keyword);
      if (brandData.result === 1) {
        this.optionBrand = brandData.detail;
      }
    },
    async onChangeStatus(val) {
      const { id, previewOrPublish } = val;

      const respone = await this.$services.eventgroup.savePublish(
        id,
        previewOrPublish
      );
      if (respone.result === 0) {
        this.$emit("getEventGroupDetail");
      }
      this.$emit("openModalAlertText", respone.message);
    },
    async getOptionCategory(keyword) {
      const categoryData = await this.$services.master.getOptionCategory(
        keyword
      );
      if (categoryData.result === 1) {
        this.optionCategory = categoryData.detail;
      }
    },

    onFileChange(e) {
      this.$emit("onFileChange", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.render-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 200px;
  border: 1px dashed gray;
}
</style>
