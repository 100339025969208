import { render, staticRenderFns } from "./createBanner.vue?vue&type=template&id=a3859ed8&scoped=true&"
import script from "./createBanner.vue?vue&type=script&lang=js&"
export * from "./createBanner.vue?vue&type=script&lang=js&"
import style0 from "./createBanner.vue?vue&type=style&index=0&id=a3859ed8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3859ed8",
  null
  
)

export default component.exports