<template>
  <div>
    <SideBarFilter
      :filter="filter"
      ref="sidebarCreateBanner"
      placeholder="Banner"
      @searchAll="haddleSave"
      @clearForm="clearForm"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      :isLoading="loading"
      handleSideBar
      hideFilter
      :textSubmit="form?.id ? 'Update' : 'Create'"
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <UploadFile
            textFloat="Image"
            placeholder="Please Choose File"
            format="image"
            name="file"
            text="* ขนาดรูปภาพที่แนะนำ 2000 * 900"
            accept="image/*"
            id="uploadfile"
            required="Please select image."
            isRequired
            @onFileChange="onFileChange($event)"
            :fileName="form.imgUrl"
            v-model="form.imgUrl"
            :v="$v.form.imgUrl"
            :isValidate="$v.form.imgUrl.$error"
          />
        </div>

        <div
          class="render-image"
          :style="`background-image:url(${imageDisplay})`"
        ></div>
        <!-- <div class="my-3">
          <UploadFile
            textFloat="Image Mobile"
            placeholder="Please Choose File"
            format="image"
            name="file"
            text="* ขนาดรูปภาพที่แนะนำ 900 * 900"
            accept="image/*"
            id="uploadfile"
            required="Please select image."
            isRequired
            @onFileChange="onFileChange($event, 2)"
            :fileName="form.mobileImgUrl"
            v-model="form.mobileImgUpload.base64"
            :v="$v.form.mobileImgUpload.base64"
            :isValidate="$v.form.mobileImgUpload.base64.$error"
          />
        </div>
        <div
          class="render-image"
          :style="`background-image:url(${form.mobileImgUpload.base64})`"
        ></div> -->
        <!-- <div class="my-3">
          <div>
            <div class="font-weight-bold mb-2">
              Start Date (DD/MM/YYYY)
              <span class="text-error">*</span>
            </div>
            <div
              class="input-container"
              :class="$v.form.startTimeDisplay.$error && 'border-red'"
            >
              <datetime
                v-model="form.startTimeDisplay"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createStartDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createStartDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="m-0 text-error" v-if="$v.form.startTimeDisplay.$error">
              Please select date
            </p>
          </div>
        </div> -->
        <!-- <div class="my-3">
          <div>
            <div class="font-weight-bold mb-2">
              End Date (DD/MM/YYYY)
              <span class="text-error">*</span>
            </div>
            <div
              class="input-container"
              :class="$v.form.endTimeDisplay.$error && 'border-red'"
            >
              <datetime
                v-model="form.endTimeDisplay"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createEndDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="m-0 text-error" v-if="$v.form.endTimeDisplay.$error">
              Please select date
            </p>
          </div>
        </div> -->
        <div class="my-3">
          <InputText
            v-model="form.sortOrder"
            class="input"
            textFloat="Sort Order"
            placeholder="0"
            type="number"
            name="number"
            isRequired
            :isValidate="$v.form.sortOrder.$error"
            :v="$v.form.sortOrder"
          />
        </div>
        <!-- <div class="my-3">
          <InputSelect
            v-model="form.bannerTypeId"
            title="Banner Type"
            name="type"
            isRequired
            class="mt-2"
            :options="optionBannerType"
            valueField="id"
            textField="name"
            @onDataChange="($event) => (form.bannerTypeId = $event)"
            :isValidate="$v.form.bannerTypeId.$error"
            :v="$v.form.bannerTypeId"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- Select Category --
              </b-form-select-option>
            </template>
          </InputSelect>
        </div> -->
        <!-- <div class="my-3">
          <InputText
            v-model="form.url"
            class="input"
            textFloat="External link"
            placeholder="External link"
            type="text"
            name="Externallink"
            :isRequired="form.bannerTypeId == 6"
            :disabled="form.bannerTypeId != 6"
            :isValidate="$v.form.url.$error"
            :v="$v.form.url"
          />
        </div> -->
        <div class="my-3">
          <b-form-checkbox
            v-model="form.isDeleted"
            switch
            :value="0 || false"
            :unchecked-value="1 || true"
          >
            Active
          </b-form-checkbox>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";

import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "CreateBanner",
  components: { UploadFile },
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  validations() {
    return {
      form: {
        imgUrl: { required },
        // base64: {
        //   required,
        // },
        // mobileImgUpload: {
        //   base64: { required },
        // },
        // startTimeDisplay: { required },
        // endTimeDisplay: { required },
        sortOrder: { required, minValue: minValue(1) },
        // bannerTypeId: { required },
        // url: {
        //   required: requiredIf(() => {
        //     return this.form.bannerTypeId == 6;
        //   }),
        // },
      },
    };
  },
  data() {
    return {
      form: {},
      // form: {
      //   sortOrder: 0,
      //   isDeleted: 0,
      //   isVideo: 0,
      //   base64: {
      //     base64: null,
      //   },
      //   imgUrl: "",
      // },

      filter: {},
      imageDisplay: "",
      loading: false,

      // optionBannerType: [],

      // styleDatetime: {
      //   width: "100%",
      //   border: "none",
      // },
    };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.form = this.data;
        this.imageDisplay = this.data.imgShowUrl;
        this.getOptionBannerType();
        this.$refs.sidebarCreateBanner.show();
      } else {
        this.$refs.sidebarCreateBanner.hide();
      }
    },
  },
  methods: {
    async getOptionBannerType() {
      const getData = await this.$services.master.getOptionBannerType();

      if (getData.result == 1) {
        this.optionBannerType = getData.detail;
      }
    },
    async haddleSave() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.loading = true;
      let respone = null;
      const body = {
        ...this.form,
        isDeleted: +this.form.isDeleted,
        isVideo: +this.form.isVideo,
      };
      if (this.form?.id) {
        respone = await this.$services.eventgroup.updateBannerTemplate(
          this.$route.params.id,
          this.form.id,
          body
        );
      } else {
        respone = await this.$services.eventgroup.insertBannerTemplate(
          this.$route.params.id,
          body
        );
      }

      this.clearForm();
      this.loading = false;
      this.openModalAlertText(respone.detail);
      this.$emit("fetchTable");
    },
    clearForm() {
      this.$v.$reset();
      this.form = {
        sortOrder: 0,
        isDeleted: 0,
        isVideo: 0,
        base64: {
          base64: null,
        },
        imgUrl: "",
      };
      this.textModal = "";
      this.imageDisplay = "";
      this.$emit("clearForm");
    },
    onFileChange(file, type) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (result) => {
        this.form.imgUrl = file.name;
        if (this.form.id) {
          const base64 = {
            base64: result.target.result,
          };
          this.form = { ...this.form, base64 };
        } else {
          this.form.base64.base64 = result.target.result;
        }

        this.imageDisplay = result.target.result;
      };
    },
    openModalAlertText(text) {
      this.$emit("openModalAlertText", text);
    },
  },
};
</script>

<style lang="scss" scoped>
.render-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 200px;
  border: 1px dashed gray;
}
.border-red {
  border-color: red;
}
</style>
