<template>
  <div class="p-2">
    <b-row class="tw-mb-2">
      <b-col cols="12">
        <div class="d-flex">
          <div class="g-form">
            <div class="input tw-w-full">
              <b-form-input
                v-model="filter.eventName"
                placeholder="Branch"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="getEventList"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
              >
                <b-icon
                  @click="getEventList"
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <b-button class="btn-filter right ml-2" @click="handleFilter">
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text">Filter</span>
            </span>
          </b-button>

          <b-button
            class="btn-filter right create tw-ml-2"
            @click="handleCreateEventList"
            v-if="visibleBtnCreate"
          >
            <span class="d-none d-md-block">
              <span>Create Branch</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(eventId)="{ item }">
        <p
          class="m-0 tw-underline tw-cursor-pointer"
          @click="handleClickEdit(item.eventId)"
        >
          {{ item.eventId || "-" }}
        </p>
      </template>
      <template #cell(isActive)="{ item }">
        <p
          :class="`m-0 ${
            item.isActive === true ? 'tw-text-green-500' : 'tw-text-red-500'
          }`"
        >
          {{ item.isActive === true ? "Active" : "InActive" }}
        </p>
      </template>
      <template #cell(actions)="{ item }">
        <div class="container">
          <font-awesome-icon
            class="mr-2 pointer"
            icon="pen"
            @click="handleClickEdit(item.eventId)"
          />
          <b-form-checkbox
            @change="handleChangeIsActive(item)"
            v-model="item.isActive"
            class="pointer"
            switch
            :value="true"
            :unchecked-value="false"
          />
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
    <filter-branch
      :visible="visible"
      :filterOption="filter"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import FilterBranch from "./filterBranch.vue";
export default {
  name: "BranchTab",
  components: { Pagination, FilterBranch },
  data() {
    return {
      visible: false,
      fields: [
        {
          key: "eventId",
          label: "Id",
          class: "w-20px text-nowrap",
        },
        {
          key: "name",
          label: "Name",
          class: "w-70px text-nowrap",
        },
        {
          key: "venueName",
          label: "Branch",
          class: "w-50px text-nowrap",
        },
        {
          key: "isActive",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action" },
      ],
      items: [],
      rows: 0,
      isBusy: false,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 15, text: "15 / page" },
        { value: 20, text: "20 / page" },
      ],
      filter: {
        eventName: null,
        previewOrPublish: null,
        isActive: null,
        eventGroupId: +this.$route.params.id,
        skip: 1,
        take: 10,
        page: 1,
      },
      iconSearch: false,
      levelCreateServiceBranch: 13,
    };
  },
  computed: {
    visibleBtnCreate() {
      return !!this.$store.state.permission.Permission.find(
        (e) => e.permissionMenuId === this.levelCreateServiceBranch
      );
    },
  },
  mounted() {
    this.getEventList();
  },
  methods: {
    async getEventList() {
      this.isBusy = true;

      const getData = await this.$services.event.getEventList(this.filter);

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }

      this.isBusy = false;
    },
    async handleChangeIsActive(val) {
      const { eventId, isActive } = val;
      const respone = await this.$services.eventgroup.saveStatus(
        eventId,
        isActive
      );
      this.openModalAlertText(respone.message);
    },
    handleCreateEvent() {
      this.$router.push(
        `/service/${this.$route.params.id}/createServiceBranch`
      );
    },
    handleCreateEventList() {
      this.$router.push(
        `/service/${this.$route.params.id}/createServiceBranchList`
      );
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getEventList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getEventList();
    },
    handleFilter() {
      this.visible = true;
    },
    searchAll(val) {
      this.getEventList();
      this.visible = false;
    },
    clearFilter(val) {
      this.filter.previewOrPublish = null;
      this.filter.eventName = null;
      this.getEventList();
      this.visible = false;
    },
    clearForm() {
      this.visible = false;
    },
    handleClickEdit(id) {
      this.$router.push(`/servicebranch/${this.$route.params.id}/detail/${id}`);
    },
    openModalAlertText(val) {
      this.$emit("openModalAlertText", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-form {
  width: 100%;
}
.btn-filter {
  &.right {
    float: right;
  }

  &.create {
    min-width: 110px !important;
  }
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  min-width: 80px;
}
</style>
