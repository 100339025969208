<template>
  <div class="tw-p-2">
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-mb-4">
      <div class="tw-text-left tw-text-xl tw-w-1/4 tw-font-bold">Banner</div>
      <div class="tw-text-right tw-w-3/4">
        <b-button class="tw-text-left" @click="handleClickCreate"
          >Create</b-button
        >
      </div>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(imgShowUrl)="{ item }">
        <img class="render-image" :src="item.imgShowUrl" />
      </template>

      <template #cell(isDeleted)="{ item }">
        <p
          class="m-0"
          :class="item.isDeleted == false ? 'color-green' : 'color-red'"
        >
          {{ item.isDeleted == false ? "Active" : "Inactive" }}
        </p>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <font-awesome-icon
          class="mr-4 pointer"
          icon="pen"
          @click="editBanner(item)"
        />
        <font-awesome-icon
          class="pointer"
          icon="trash-alt"
          @click="removeBanner(item)"
        />
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />

    <create-banner
      :visible="visible"
      :data="form"
      @fetchTable="getgetBannerTemplateList"
      @clearForm="clearForm"
      @openModalAlertText="openModalAlertText"
    />

    <ModalConfirm
      id="cancel"
      ref="removeBannerEventGroup"
      text="You want remove this banner ?"
      @handler="handleRemove"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import CreateBanner from "./createBanner.vue";
import ModalConfirm from "@/components/modal/ModalConfirm";
export default {
  name: "BannerTab",
  components: { Pagination, CreateBanner, ModalConfirm },

  data() {
    return {
      items: [],
      fields: [
        {
          key: "imgShowUrl",
          label: "Image Destop",
          class: "w-100px text-nowrap",
        },

        {
          key: "sortOrder",
          label: "Sort Order",
          class: "w-50px text-nowrap",
        },
        {
          key: "isDeleted",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      rows: 0,
      isBusy: false,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 15, text: "15 / page" },
        { value: 20, text: "20 / page" },
      ],
      filter: {
        skip: 1,
        take: 5,
        page: 1,
      },
      visible: false,
      form: {
        sortOrder: 0,
        isDeleted: 0,
        isVideo: 0,
        base64: {
          base64: null,
        },
        imgUrl: "",
      },
    };
  },
  mounted() {
    this.getgetBannerTemplateList();
  },
  methods: {
    async getgetBannerTemplateList() {
      this.isBusy = true;
      const { page, take } = this.filter;
      const id = this.$route.params.id;
      const respone = await this.$services.eventgroup.getBannerTemplate(
        id,
        page,
        take
      );
      if (respone.result === 1) {
        const { count, data } = respone.detail;
        this.rows = count;
        this.items = data;
      }
      this.isBusy = false;
    },
    async removeBanner(obj) {
      this.form = obj;
      this.$refs.removeBannerEventGroup.show();
    },
    async handleRemove(val) {
      if (val === true) {
        const body = {
          ...this.form,
          isDeleted: 1,
          isVideo: +this.form.isVideo,
        };
        const respone = await this.$services.eventgroup.updateBannerTemplate(
          this.$route.params.id,
          this.form.id,
          body
        );
        this.getgetBannerTemplateList();
      } else {
        this.clearForm();
        this.$refs.removeBannerEventGroup.close();
      }
    },
    editBanner(obj) {
      this.form = obj;
      this.visible = true;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getgetBannerTemplateList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getgetBannerTemplateList();
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    handleClickCreate() {
      this.visible = true;
    },
    clearForm() {
      this.form = {
        sortOrder: 0,
        isDeleted: 0,
        isVideo: 0,
        base64: {
          base64: null,
        },
        imgUrl: "",
      };
      this.visible = false;
    },
    openModalAlertText(val) {
      this.$emit("openModalAlertText", val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}

.color-red {
  color: #dc3545;
}

.color-green {
  color: #28a745;
}

.color-blue {
  color: lightcoral;
}

.text-time {
  opacity: 0.5;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.render-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 160px;
  min-height: 160px;
  margin: auto;
}
</style>
