<template>
  <div class="">
    <div class="header-eventgroup">
      <h1 class="tw-p-4">Service Setting</h1>

      <b-dropdown
        v-if="language"
        :text="language.name"
        class="tw-h-10 tw-m-4 tw-min-w-10"
      >
        <b-dropdown-item
          v-for="(item, index) in listLang"
          :key="index"
          @click="changeLanguage(item, index)"
        >
          <div :class="language.languageId === item.languageId && `item`">
            {{ item.name }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="tw-bg-white tw-min-h-screen">
      <b-tabs
        content-class="mt-3"
        active-nav-item-class="font-weight-bold "
        justified
        v-model="tabIndex"
      >
        <b-tab title="Detail" :title-item-class="titleItemClass">
          <detail-tab
            :form="form"
            :index-language="indexLanguage"
            :language="language"
            :loading="loading"
            @onFileChange="onFileChange"
            :v="$v"
            @openModalAlertText="openModalAlertText"
            :visibleRecommend="getUserDetail.adminRoleAccessTypeId === 1"
            @getEventGroupDetail="getEventGroupDetail"
          />
        </b-tab>
        <b-tab
          title="Template Service Setting"
          :title-item-class="titleItemClass"
        >
          <div v-if="tabIndex === 1">
            <service-setting-tab
              :form="form"
              :index-language="indexLanguage"
              :language="language"
              :v="$v"
            />
          </div>
        </b-tab>
        <b-tab title="Template Banner" :title-item-class="titleItemClass">
          <div v-if="tabIndex === 2">
            <banner-tab @openModalAlertText="openModalAlertText" />
          </div>
        </b-tab>

        <b-tab
          title="Template Additional Data"
          :title-item-class="titleItemClass"
        >
          <div v-if="tabIndex === 3">
            <additional-data-tab
              :data="form.additionalData"
              :selectLang="language"
              :v="$v"
            /></div
        ></b-tab>

        <b-tab title="Service Branch">
          <div v-if="tabIndex === 4">
            <branch-tab @openModalAlertText="openModalAlertText" /></div
        ></b-tab>
      </b-tabs>
    </div>
    <div
      class="tw-sticky tw-bottom-0 tw-bg-white tw-z-40 tw-w-full tw-h-12 tw-px-3"
      v-if="titleItemClass === ''"
    >
      <div class="footer-event-setting">
        <b-button class="btn-filter cancel" @click="onClickCancel"
          >Cancel</b-button
        >
        <b-button
          class="btn-filter save"
          @click="onClickSave"
          :disabled="disabledButton"
          >Save
          <b-spinner
            class="m-0 ml-1"
            label="Spinning"
            small
            v-if="disabledButton"
          ></b-spinner
        ></b-button>
      </div>
    </div>
    <ModalAlertText :textModal="textModal" :arrModal="[]" :size="sizeModal" />
  </div>
</template>

<script>
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
import DetailTab from "./component/detailTab.vue";
import BannerTab from "./component/bannerTab.vue";
import AdditionalDataTab from "./component/additionalDataTab.vue";
import BranchTab from "./component/branchTab.vue";
import ServiceSettingTab from "./component/serviceSettingTab.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import { mapGetters } from "vuex";
export default {
  name: "ServiceDetail",
  components: {
    DetailTab,
    BannerTab,
    AdditionalDataTab,
    BranchTab,
    ModalAlertText,
    ServiceSettingTab,
  },
  data() {
    return {
      sizeModal: "md",
      textModal: "",
      disabledButton: false,
      loading: true,

      tabIndex: null,

      selectedLang: 2,
      listLang: [],

      form: {
        id: 0,
        urlKey: "",
        imgUrl: "",
        base64: {
          base64: "",
        },
        brandId: 0,
        categoryId: 0,
        isDisplay: true,
        previewOrPublish: true,
        translationList: [
          {
            id: 0,
            name: "",
            languageId: 2,
            shortDescription: "",
          },
          {
            id: 0,
            name: "",
            languageId: 1,
            shortDescription: "",
          },
        ],
        additionalData: [
          {
            eventInfoId: 0,
            sortOrder: 0,
            isDisplay: 0,
            additionalLanguageData: [
              {
                id: 0,
                languageId: 2,
                topic: "",
                detail: "",
              },
              {
                id: 0,
                languageId: 1,
                topic: "",
                detail: "",
              },
            ],
          },
        ],
      },
    };
  },
  validations() {
    return {
      form: {
        urlKey: { required },
        imgUrl: { required },
        brandId: { required },
        categoryId: { required },
        eventGroupPrice: { required, minValue: minValue(0) },
        additionalData: {
          $each: {
            additionalLanguageData: {
              $each: {
                topic: {
                  required: requiredIf((x) => {
                    return x.languageId == 2 && x.topic == "";
                  }),
                },
                detail: {
                  required: requiredIf((x) => {
                    return x.languageId == 2 && x.detail == "";
                  }),
                },
              },
            },
          },
        },
        translationList: {
          $each: {
            name: {
              required: requiredIf(() => {
                const requiredLanguage = this.form.translationList.find(
                  (x) => x.languageId === 2
                );
                return !requiredLanguage.name && this.selectedLang === 2;
              }),
            },
            // shortDescription: {
            //   required: requiredIf(() => {
            //     const requiredLanguage = this.form.translationList.find(
            //       (x) => x.languageId === 2
            //     );
            //     return (
            //       !requiredLanguage.shortDescription && this.selectedLang === 2
            //     );
            //   }),
            // },
            // description: {
            //   required: requiredIf(() => {
            //     const requiredLanguage = this.form.translationList.find(
            //       (x) => x.languageId === 2
            //     );
            //     return !requiredLanguage.description && this.selectedLang === 2;
            //   }),
            // },
          },
        },
      },
    };
  },
  mounted() {
    this.defaultTabIndex();
    this.getEventGroupDetail();
    this.getLanguageOption();
  },
  computed: {
    ...mapGetters(["getUserDetail"]),
    titleItemClass() {
      return this.getUserDetail.adminRoleAccessTypeId <= 2 ? "" : "d-none";
    },
    language() {
      return this.listLang.length > 0
        ? this.listLang.find((x) => x.languageId === this.selectedLang)
        : {};
    },
    indexLanguage() {
      return this.form.translationList.findIndex(
        (x) => x.languageId === this.selectedLang
      );
    },
  },
  methods: {
    async onClickSave() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        this.tabIndex = 0;
        return;
      }
      this.adapterForm();
      this.disabledButton = true;
      const id = this.$route.params.id;
      const respone = await this.$services.eventgroup.saveEventGroup(
        id,
        this.form
      );
      this.textModal = respone.message;
      this.disabledButton = false;
      if (respone.result === 1) {
        this.sizeModal = "md";
      } else {
        this.sizeModal = "lg";
      }
      this.openModalAlertText();

      this.getEventGroupDetail();
    },
    async getEventGroupDetail() {
      this.loading = true;

      const respone = await this.$services.eventgroup.eventGroupDetail(
        this.$route.params.id
      );
      if (respone.result === 1) {
        this.form = respone.detail;

        //mock
        // this.form.previewOrPublish = respone.detail;

        this.loading = false;
      } else {
        this.$router.push(`/service`);
      }
    },

    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();

      if (getData.result == 1) {
        this.listLang = getData.detail;
        this.selectedLang = this.listLang[0].languageId;
      }
    },
    adapterForm() {
      if (this.form.additionalData.length > 0) {
        this.form.additionalData.forEach((e) => {
          if (typeof e.eventInfoId === "string") {
            e.eventInfoId = 0;
          }
        });
      }
    },
    openModalAlertText(val) {
      if (val) {
        this.textModal = val;
      }
      this.$bvModal.show("modal-alert-text");
    },
    changeLanguage(v) {
      this.selectedLang = v.languageId;
    },
    onFileChange(e) {
      this.form.imgUrl = e.name;
      let reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = (file) => {
        const { result } = file.target;
        this.form.imgShowUrl = result;
        this.form.base64 = { base64: result };
      };
    },
    onClickCancel() {
      this.$router.push(`/service`);
    },
    async defaultTabIndex() {
      if (+this.$route.query.tab) {
        this.tabIndex = await +this.$route.query.tab;
      } else if (this.getUserDetail.adminRoleAccessTypeId >= 3) {
        this.tabIndex = await 4;
      } else {
        this.tabIndex = await 0;
      }
    },
  },
};
</script>

<style lang="scss">
.nav-link.active {
  color: var(--secondary-color) !important;
  border-bottom: 4px solid var(--secondary-color) !important;
}

.nav-link:hover {
  border-color: transparent transparent var(--secondary-color) transparent !important;
}

.header-eventgroup {
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.item {
  color: var(--secondary-color);
}
.btn-color {
  background-color: var(--secondary-color);
  color: white;
}
.footer-event-setting {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
// ! override
.btn-filter {
  width: 100%;
  max-width: 150px;

  &.cancel {
    border: none;
    background: #606060;
    color: white !important;
  }

  &.save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border: none;
    color: white !important;
  }
  &.save:hover {
    background: var(--secondary-color);

    cursor: pointer;
  }
  &.cancel:hover {
    background: #606060;
    color: white !important;

    cursor: pointer;
  }
}
</style>
