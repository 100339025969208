<template>
  <div class="tw-p-2">
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row>
        <b-col>
          <div class="header-title">Description</div>
          <div class="my-3">
            <TextEditorsTiny
              v-model="form.translationList[indexLanguage].description"
              textFloat="Description"
              :rows="4"
              :name="'description_' + 1"
              placeholder="Type something..."
              :selectLang="language"
              @onDataChange="
                (val) => (form.translationList[indexLanguage].description = val)
              "
            />

            <!-- isRequired
              :v="v.form.translationList.$each[indexLanguage].description"
              :isValidate="
                v.form.translationList.$each[indexLanguage].description.$error
              " -->
          </div></b-col
        ></b-row
      >
      <b-row>
        <b-col>
          <div class="my-3">
            <InputTextArea
              v-model="form.translationList[indexLanguage].shortDescription"
              class="input"
              :textFloat="`ShortDescription`"
              placeholder="ShortDescription"
              type="text"
              rows="4"
              name="name"
              :selectLang="language"
            />
            <!-- isRequired
              :v="v.form.translationList.$each[indexLanguage].shortDescription"
              :isValidate="
                v.form.translationList.$each[indexLanguage].shortDescription
                  .$error
              " -->
          </div></b-col
        ></b-row
      >
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ServiceSettingTab",
  props: {
    form: {
      type: Object,
      required: true,
    },
    indexLanguage: {
      type: Number,
      required: true,
    },
    language: {
      type: Object,
      required: false,
    },
    v: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  padding: 15px;
  background: var(--secondary-color);
  color: var(--font-color);
  .title {
    color: white;
  }
}
</style>
